import React from "react";
import { Link } from "react-router-dom";
import homeDoor from "./../images/homePageDoor.jpg";
import { motion } from "framer-motion";

export default function NotFound() {
  return (
    <motion.section className="notFoundWrapper">
      <h1>
        Oh No!
        <br /> It Seems You Got Lost.
      </h1>
      <p>Don't Worry We Can Help You Get Home.</p>
      <p>Just Head On Through The Door.</p>
      <Link to="/">
        <motion.img
          src={homeDoor}
          alt="Back To Home"
          initial={{ transform: "scale(1)" }}
          exit={{
            transform: "scale(20)",
            transition: {
              duration: 2.5,
            },
          }}
        />
      </Link>
    </motion.section>
  );
}
