import React from "react";
import { motion } from "framer-motion";
import pageVariants from "./pageVariants";

const questionParentVariant = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const questionChildVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      type: "tween",
    },
  },
};

export default function Faq() {
  return (
    <motion.section
      className="faqWrapper"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <h1>FAQ</h1>
      <motion.div
        className="questions"
        variants={questionParentVariant}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="question" variants={questionChildVariant}>
          <h3>
            <u>How Much Will It Cost?</u>
          </h3>
          <p>
            Every project is different in every way. Contact us and we will get
            a quote for you!
          </p>
        </motion.div>

        <motion.div className="question" variants={questionChildVariant}>
          <h3>
            <u>What Is Maintainable Code?</u>
          </h3>
          <p>
            Maintainable code means that in a modern, fast paced world, when
            your business changes, your site can be changed even faster! New
            font or color scheme you want to change to? No problem our
            maintainable code allows us to change all that in under a day.
          </p>
        </motion.div>

        <motion.div className="question" variants={questionChildVariant}>
          <h3>
            <u>Why Do I Need a Custom Site?</u>
          </h3>
          <p>
            No matter what your want your site for. Most first impressions of
            what you represent will be your website. First impressions mean
            everything when it comes to aquiring new customers and continued
            business.
          </p>
        </motion.div>

        <motion.div className="question" variants={questionChildVariant}>
          <h3>
            <u>Can You Do This On My Site?</u>
          </h3>
          <p>
            If you've seen an animation or style that caught your eye and want
            to implement it into your site. We can do it!
          </p>
        </motion.div>

        <motion.div className="question" variants={questionChildVariant}>
          <h3>
            <u>Can You Update My Site?</u>
          </h3>
          <p>
            We can and will! Unfortunatly some older code takes more time to
            alter and maintain, but there is no code we can't make shine!
          </p>
        </motion.div>

        <motion.div className="question" variants={questionChildVariant}>
          <h3>
            <u>What Does HTML Stand For?</u>
          </h3>
          <p>
            HTML stands for "Hyper Text Markup Language" it's the base code
            websites are made of!
          </p>
        </motion.div>
      </motion.div>
    </motion.section>
  );
}
