import React from "react";
import { motion } from "framer-motion";
import pageVariants from "./pageVariants";

export default function About() {
  return (
    <motion.section
      className="aboutWrapper"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <h1>About Us</h1>
      <p>
        We are a small dedicated group of like minded developers that work like
        a well oiled machine. Each project we work on has a touch of everyones
        distinct personality in it. Always striving to make the best product, to
        find that perfect balance of design and functionality. Every business is
        unique in almost every way. So why should anyone settle for less than a
        product made from the essence of their business? No project is too big
        or small for us. We enjoy everything we do, and know it's the little
        details that always matter!
      </p>
      <br />
      <p>
        Did you know that it takes about 50 miliseconds for someone to judge if
        they like your website or not? Yes that is 0.05 seconds, if they decide
        to stay or leave. These crucial seconds.... or in this case miliseconds
        are where we come in. Our sites will pull your customers in and show
        them your quality and passion.
      </p>
      <br />
      <p>
        The world has gone mobile. Mobile devices now cover more than 50% of all
        internet usage worldwide. That means your site not only has to look
        great, but be dynamic and look great on every screen it comes up on.
        From a 60 inch TV, to that screen that slides in your pocket. At
        GazelleHTML we guarantee your site will look as good, or even better on
        a mobile device. We work with the most modern designs, techniques, and
        technology that will set you apart from all others.
      </p>
    </motion.section>
  );
}
