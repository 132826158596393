const pageVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: .5,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: .5,
        }
    },
};

export default pageVariants;