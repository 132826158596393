import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "./../images/gazelleHTMLLogoWhite.svg";

export default function NavBar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobileDown, setMobileDown] = useState(false);

  const navBarRef = useRef();
  const mobileNav1 = useRef();
  const mobileNav2 = useRef();
  const mobileNav3 = useRef();

  const animateNav = () => {
    const navBar = navBarRef.current;
    const navLine1 = mobileNav1.current;
    const navLine2 = mobileNav2.current;
    const navLine3 = mobileNav3.current;
    if (!mobileDown) {
      navBar.style.top = "50px";
      navLine1.style.transform = "translateY(9px)";
      navLine1.style.backgroundColor = "#A51A41";
      navLine2.style.opacity = "0";
      navLine3.style.transform = "translateY(-9px)";
      //collapse then rotate
      setTimeout(() => {
        navLine1.style.transform = "translateY(9px) rotate(-45deg)";
        navLine3.style.transform = "translateY(-9px) rotate(45deg)";
      }, 400);
      navLine3.style.backgroundColor = "#A51A41";
      setMobileDown(true);
    } else {
      navBar.style.top = "-60vh";
      navLine1.style.transform = "translateY(9px) rotate(0)";
      navLine1.style.backgroundColor = "white";
      navLine3.style.transform = "translateY(-9px) rotate(0)";
      //rotate then open
      setTimeout(() => {
        navLine1.style.transform = "";
        navLine2.style.opacity = "1";
        navLine3.style.transform = "";
      }, 400);
      navLine3.style.backgroundColor = "white";
      setMobileDown(false);
    }
  };

  const handleClick = () => {
    //if mobile nav is down slide nav up on page change
    if (windowWidth < 1000 && mobileDown) {
      setMobileDown(true);
      animateNav();
    }
  };

  useEffect(() => {
    function handleResize() {
      //change nav bar on window resize
      const navBar = navBarRef.current;
      if (windowWidth > 1000) {
        setWindowWidth(window.innerWidth);
        navBar.style.top = "0";
      } else {
        navBar.style.top = "-60vh";
      }
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <section className="navBar">
      <img id="navLogo" src={logo} alt="GazelleHTML" />
      <div className="mobileNav" onClick={animateNav}>
        <div className="bar1" ref={mobileNav1}></div>
        <div className="bar2" ref={mobileNav2}></div>
        <div className="bar3" ref={mobileNav3}></div>
      </div>

      <ul ref={navBarRef}>
        <Link className="navLink" to="/" onClick={handleClick}>
          Home
        </Link>
        <Link className="navLink" to="/about" onClick={handleClick}>
          About
        </Link>
        <Link className="navLink" to="/services" onClick={handleClick}>
          Services
        </Link>
        <Link className="navLink" to="/faq" onClick={handleClick}>
          FAQ
        </Link>
        <Link className="navLink" to="contact" onClick={handleClick}>
          Contact
        </Link>
      </ul>
    </section>
  );
}
