import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import pageVariants from "./pageVariants";

//img imports
import mobile from "./../images/horizonMobile.jpg";
import desktop from "./../images/horizonDesktop.jpg";

import phone from "./../images/Icons/phoneIcon.svg";
import email from "./../images/Icons/emailIcon.svg";

export default function Contact() {
  const headerRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      //change header image on window resize
      const header = headerRef.current;
      if (windowWidth > 1000) {
        header.style.backgroundImage = `url(${desktop})`;
      } else {
        header.style.backgroundImage = `url(${mobile})`;
      }
      setWindowWidth(window.innerWidth);
    }
    //add listener on mount
    window.addEventListener("resize", handleResize);
    //remove on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <motion.section
      className="contactWrapper"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      transition="transition"
      exit="exit"
    >
      <div
        ref={headerRef}
        className="contactHeader"
        style={{
          backgroundImage:
            window.innerWidth < 1000 ? `url(${mobile})` : `url(${desktop})`,
        }}
      >
        <h1>Contact</h1>
        <p>We See Something Great On The Horizon.</p>
      </div>
      <div className="contactInfo">
        <div className="contact" id="phone">
          <img src={phone} alt="Phone Number" />
          <a href="tel:19048688517">(904)868-8517</a>
        </div>
        <div className="contact">
          <img src={email} alt="Email" />
          <a href="mailto:gazellehtml@gmail.com">
            Gazelle<span>HTML</span>@gmail.com
          </a>
        </div>
      </div>
    </motion.section>
  );
}
