import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Loader5.css";

//img imports
import image1 from "./../../images/loaderImages/image1.jpg";
import image2 from "./../../images/loaderImages/image2.jpg";
import image3 from "./../../images/loaderImages/image3.jpg";
import image4 from "./../../images/loaderImages/image4.jpg";
import image5 from "./../../images/loaderImages/image5.jpg";

export default function Loader5({ loaded, toLoad }) {
  const images = [image1, image2, image3, image4, image5];
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = toLoad;
    imageToLoad.onload = () => {
      loaded(true);
    };
    //cycle images
    const cycleImages = setInterval(() => {
      setActiveIndex((current) =>
        current === images.length - 1 ? 0 : current + 1
      );
    }, 5000);
    //clear interval on removal
    return () => clearInterval(cycleImages);
  }, [images.length, toLoad, loaded]);

  return (
    <motion.div
      className="loaderContainer"
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <div className="loader5">
        <img id="backImage" src={images[activeIndex]} alt="" />
        <motion.div
          className="panel"
          id="panel1"
          animate={{
            y: [0, -200, -200, 0, 0, 0],
            transition: {
              duration: 5,
              type: "tween",
              delay: 0.6,
              repeat: Infinity,
            },
          }}
        />
        <motion.div
          className="panel"
          id="panel2"
          animate={{
            x: [0, 200, 200, 200, 0, 0, 0],
            transition: {
              duration: 5,
              type: "tween",
              delay: 0.5,
              repeat: Infinity,
            },
          }}
        />
        <motion.div
          className="panel"
          id="panel3"
          animate={{
            y: [0, 200, 200, 200, 200, 0, 0],
            transition: { duration: 5, type: "tween", repeat: Infinity },
          }}
        />
      </div>
      Loading
    </motion.div>
  );
}
