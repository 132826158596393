import React from "react";
import { motion } from "framer-motion";
import pageVariants from "./pageVariants";

const fadeInVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "tween",
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
};

const slideLeftFadeInVariant = {
  hidden: {
    x: 30,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      type: "tween",
    },
  },
};

export default function Services() {
  return (
    <motion.section
      className="servicesWrapper"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="servicesHead">
        <h1>Services</h1>
      </div>
      <motion.div
        className="service"
        id="design"
        variants={fadeInVariant}
        initial="hidden"
        animate="visible"
      >
        <h2>Web Design</h2>
        <ul>
          <motion.li variants={slideLeftFadeInVariant}>
            Create New Sites
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Update Existing Sites
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Fix Existing Bugs
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Eye Catching Techniques
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Custom Animations
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>Modern Styles</motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Memorable Pages
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Smooth Transitions
          </motion.li>
        </ul>
      </motion.div>

      <motion.div
        className="service"
        id="webAppDevelopment"
        variants={fadeInVariant}
        initial="hidden"
        animate="visible"
      >
        <h2>Web App Development</h2>
        <ul>
          <motion.li variants={slideLeftFadeInVariant}>
            Fontend Development
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Backend Development
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Non Relational Database Management
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Relational Database Management
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>Cloud Storage</motion.li>
        </ul>
      </motion.div>

      <motion.div
        className="service"
        id="userInterfaces"
        variants={fadeInVariant}
        initial="hidden"
        animate="visible"
      >
        <h2>User Interfaces</h2>
        <ul>
          <motion.li variants={slideLeftFadeInVariant}>
            User Friendly Menus
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Complex Controls with Ease of Use
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Complex Custom Animations
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Unique Menu Designs
          </motion.li>
        </ul>
      </motion.div>

      <motion.div
        className="service"
        id="branding"
        variants={fadeInVariant}
        initial="hidden"
        animate="visible"
      >
        <h2>Branding</h2>
        <ul>
          <motion.li variants={slideLeftFadeInVariant}>
            Font Selection
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>
            Color Selection
          </motion.li>
          <motion.li variants={slideLeftFadeInVariant}>Logo Creation</motion.li>
        </ul>
      </motion.div>
    </motion.section>
  );
}
