import "./../css/styles.css";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ScrollToTop from "./ScrollToTop";
import NavBar from "./NavBar";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Services from "./Services";
import Faq from "./Faq";
import NotFound from "./NotFound";

function App() {
  const location = useLocation();

  return (
    <div className="appWrapper">
      <NavBar />
      <ScrollToTop>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/faq" component={Faq} />
            <Route path="*" component={NotFound} />
          </Switch>
        </AnimatePresence>
      </ScrollToTop>
      <footer>
        <div className="footerList">
          <Link className="footerLink" to="/home">
            Home
          </Link>
          <Link className="footerLink" to="/about">
            About
          </Link>
          <Link className="footerLink" to="/services">
            Services
          </Link>
          <Link className="footerLink" to="/faq">
            FAQ
          </Link>
          <Link className="footerLink" to="/contact">
            Contact
          </Link>
        </div>
        <p id="copyright">
          © Gazelle<span>HTML</span>
        </p>
      </footer>
    </div>
  );
}

export default App;
